import React from  'react';

import icon from './kot.jpg';

const ErrorIndicator = () => {
    return (
        <div className="error-indicator">
            <img src={icon} alt="error"/>
            <div>Something is wrong</div>
        </div>
    )
}

export default ErrorIndicator;
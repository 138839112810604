import React from "react";
import { Link } from "react-router-dom";

import "./navbar.css";

const Navbar = ({ logined, logout }) => {
  const guestLinks = (
    <ul className="navbar">
      <li>
        <Link to="/demo">Demo</Link>
      </li>
      <li>
        <Link to="/register">Register</Link>
      </li>
      <li>
        <Link to="/login">Login</Link>
      </li>
    </ul>
  );
  const userLinks = (
    <ul className="navbar">
      <li>
        <Link to="/links">Bookmarks</Link>
      </li>
      <li>
        <Link to="/account">Account</Link>
      </li>
      <li>
        <Link to="/logout" onClick={logout}>
          Logout
        </Link>
      </li>
    </ul>
  );

  return <div class="navbar-container"><a class="logo" href="/">Linka.in</a>{logined ? userLinks : guestLinks}</div>;
};

export default Navbar;

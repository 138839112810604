import "./item-action-button.css";

const ItemActionButton = ({ action, title }) => {
  return (
    <button type="button" className="item_action_button" onClick={action}>
      {title}
    </button>
  );
};

export default ItemActionButton;

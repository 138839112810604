import { Component } from "react";

export default class ApiService extends Component {
  _base_prod = "https://linka.in/";
  _base_dev = "http://localhost:5000/";

  async getResource(url, token = null) {
    //url = this._base_dev + url;
   // console.log(url);
    let config = {
      headers: {
        "Content-Type": "application/json",
        'Accept': 'application/json',
        
        ...(token && { "x-auth-token": token }),
      },
    };
    const res = await fetch(url, config);
    if (!res.ok) {
      throw new Error(`could not fetch ${url} status ${res.status}`);
    }
    const body = await res.json();

    return body;
  }

  async postResource(url, opt, token = null) {
    try {
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(opt),
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          ...(token && { "x-auth-token": token }),
        },
      });
      /*
      if (!res.ok) {
        throw new Error(`could not post ${url} status ${res.status}`);
      }*/
      return res;
    } catch (error) {
      console.error("There is an error:" + error.message);
    }
  }

  async putResource(url, opt, token = null) {
    try {
      const res = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(opt),
        headers: {
          'Accept': "application/json",
          "Content-Type": "application/json",
          ...(token && { "x-auth-token": token }),
        },
      });

      return res;
    } catch (error) {
      console.error("There is an error:" + error.message);
    }
  }

  async deleteResource(url, token = null) {
    try {
      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          'Accept': "application/json",
          "Content-Type": "application/json",
          ...(token && { "x-auth-token": token }),
        },
      });
      if (!res.ok) {
        throw new Error(`could not delete ${url} status ${res.status}`);
      }
      return res;
    } catch (error) {
      console.log(error.message);
    }
  }
  /*
  async postLink(obj) {
    try {
      const objPosted = await this.postResource("api/links/add", obj);
      const json = await objPosted.json();
      return json;
    } catch (error) {
      console.log(error.message);
    }
  }

  async deleteLink(id) {
    try {
       await this.deleteResource("api/links/" + id);
    } catch (error) {
      console.log(error.message);
    }
  }
  */
}

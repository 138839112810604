import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ApiService from "../../services/api-service.js";
import Spiner from "../spiner";
import ErrorIndicator from "../error-indicator";
import TagList from "../tag-list";
import Search from "../search";
import Panel from "../panel";
import AddForm from "../add-form";
import LinkList from "../link-list/link-list";
import Navbar from "../navbar/navbar";
import Register from "../auth/register";
import Login from "../auth/login";
import Account from "../auth/account";
import Landing from "../layout/landing";
import Logout from "../auth/logout";


export default class App extends Component {
  apiService = new ApiService();
  tagsLimit = 30;
  state = {
    links: [],
    tags: [],
    showAllTags: false,
    searchText: "",
    loading: true,
    error: false,
    validationErrors: [],
    logined: false,
    token: null,
  };
  updateLinks() {
    this.apiService
      .getResource("api/links", this.state.token)
      .then(body => {
        this.setState({
          links: body.links,
          tags: body.tags,
          loading: false,
          logined: body.token,
          token: body.token,
        });
      })
      .catch(this.onError);
  }

  onRegister = async obj => {
    try {
      var objPosted = await this.apiService.postResource("api/users", obj);
    } catch (err) {
      return this.onError(err);
    }
    try {
      let body = await objPosted.json();
      if (objPosted.status === 400) {
        this.setState({ validationErrors: body.errors });
      } else {
        let token = body.token;
        this.setState({ token: token, logined: true });
        this.updateLinks();
      }
    } catch (err) {
      this.onError(err);
    }
  };
  onLogin = async obj => {
    try {
      var objPosted = await this.apiService.postResource("api/auth", obj);
    } catch (err) {
      return this.onError(err);
    }
    try {
      let body = await objPosted.json();
      if (objPosted.status === 400) {
        this.setState({ validationErrors: body.errors });
      } else {
        let token = body.token;
        this.setState({ token: token, logined: true });
        this.updateLinks();
      }
    } catch (err) {
      this.onError(err);
    }
  };
  onLogout = async () => {
    try {
      let out = await this.apiService.deleteResource("api/auth/session");
      this.setState({
        token: null,
        logined: false,
        links: [],
        tags: [],
        searchText: "",
      });
    } catch (err) {
      this.onError(err);
    }
  };

  onUpdateLink = async obj => {
    let linksAll = this.state.links.slice();
    linksAll.some((link, ind) => {
      if (link._id === obj.id) {
        if (!link.tag) link.tag = [];
        if (!obj.tag) obj.tag = [];
        let tagToRemove = link.tag.filter(el => !obj.tag.includes(el));
        let tagToAdd = obj.tag.filter(el => !link.tag.includes(el));
        linksAll[ind].url = obj.url;
        linksAll[ind].tag = obj.tag;
        this.setState({
          links: linksAll,
          tags: this.addIncRemTags(this.state.tags, tagToAdd, tagToRemove),
        });
        return true;
      }
      return false;
    });
    if (!this.state.token) return;
    try {
      var objUpdated = await this.apiService.putResource(
        "api/links/" + obj.id,
        obj,
        this.state.token
      );
      obj = await objUpdated.json();
      if (objUpdated.status === 400) {
        this.setState({ validationErrors: obj.errors });
        return;
      }
    } catch (err) {
      return this.onError(err);
    }
  };
  addIncRemTags = (stateTags, tagsToAdd = [], tagsToRemove = []) => {
    let tagMap = new Map();
    stateTags.forEach((t, ind) => {
      tagMap.set(t.name, ind);
    });
    let tags = stateTags.slice();
    tagsToAdd.forEach(ot => {
      if (tagMap.has(ot)) {
        tags[tagMap.get(ot)].total++;
      } else {
        tags.push({ name: ot, total: 1 });
      }
    });
    tagsToRemove.forEach(ot => {
      if (tags[tagMap.get(ot)].total > 1) {
        tags[tagMap.get(ot)].total--;
      } else {
        tags.splice(tagMap.get(ot), 1);
      }
    });
    return tags;
  };

  onAddLink = async obj => {
    try {
      var objPosted = await this.apiService.postResource(
        "api/links/add",
        obj,
        this.state.token
      );
    } catch (err) {
      return this.onError(err);
    }
    try {
      obj = await objPosted.json();
      if (objPosted.status === 400) {
        this.setState({ validationErrors: obj.errors });
        return;
      }
    } catch (err) {
      this.onError(err);
    }
    this.setState({ validationErrors: [] });
    let linksNew = [...this.state.links];
    linksNew.unshift(obj);
    this.setState({
      links: linksNew,
      tags: this.addIncRemTags(this.state.tags, obj.tag),
    });
  };

  onSearchChange = searchText => {
    this.setState({ searchText });
  };

  componentDidMount() {
    this.updateLinks();
  }

  onError = error => {
    console.log(error);
    this.setState({
      error: true,
      loading: false,
    });
  };
  cutTags() {
    return this.state.tags.slice(0, this.tagsLimit);
  }
  tagClick = searchText => {
    this.setState({ searchText });
  };
  searchClear = () => {
    this.setState({ searchText: "" });
  };

  deleteLink = id => {
    if (this.state.logined) {
      this.apiService.deleteResource("api/links/" + id, this.state.token);
    }
    let linksNew = [...this.state.links];
    linksNew.some((el, i) => {
      if (el._id === id) {
        var tagsNew = this.addIncRemTags(this.state.tags, [], el.tag);
        linksNew.splice(i, 1);
        this.setState({ links: linksNew, tags: tagsNew });
        return true;
      }
      return false;
    });
  };

  render() {
    if (this.state.loading) {
      return <Spiner />;
    }
    if (this.state.error) {
      return <ErrorIndicator />;
    }
    var links = this.state.links.slice();

    if (this.state.searchText !== "") {
      const regex = new RegExp("(^|\\s)" + this.state.searchText + ".*", "i");

      links = links.filter(
        el => regex.test(el.title) || (el.tag && regex.test(el.tag.join(" ")))
      );
    }

    return (
      <div>
        <Router>
          <Switch>
            <Route path="/register">
              <Register
                onSubmit={this.onRegister}
                logined={this.state.logined}
              />
            </Route>
            <Route exact path="/login">
              <Login logined={this.state.logined} onLogin={this.onLogin} />
            </Route>
            <Route exact path="/account">
              <Account logined={this.state.logined} />
            </Route>
            <Route path="/links">
              <div className="main-container">
              <Navbar logined={this.state.logined} logout={this.onLogout} />
              <Panel title="Add New Bookmark">
                <AddForm
                  onAddLink={this.onAddLink}
                  errors={this.state.validationErrors}
                  tags={this.state.tags}
                />
              </Panel>
              <Panel>
                <Search
                  searchText={this.state.searchText}
                  tags={this.state.tags}
                  onSearchChange={this.onSearchChange}
                  onSearchClear={this.searchClear}
                />
              </Panel>
              <Panel title="Tags">
                <TagList tags={this.cutTags()} onTagClick={this.tagClick} />
              </Panel>
              <Panel title="Bookmarks">
                <LinkList
                  links={links}
                  onDelete={this.deleteLink}
                  tags={this.cutTags()}
                  onUpdateLink={this.onUpdateLink}
                />
                </Panel>
                </div>
            </Route>
            <Route path="/logout">
              <Logout onDemo={() => this.updateLinks()} />
            </Route>
            <Route path="/">
              <Landing
                logined={this.state.logined}
                onDemo={() => this.updateLinks()}
              />
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

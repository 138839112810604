import react, {Fragment, useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import './style.css';
import mainPic from './../../img/organizer.jpg';

const Landing = ({ logined, onDemo }) => {
  if (logined) {
    return <Redirect to="/links" />;
  }

  return (
      <div className="main-container">
        <div className="landing-nav">
          <div className="logo">
            Linka.in
          </div>
          <div className="links">
          <Link to="/register">Sign Up</Link>&nbsp;
      <Link to="/login">Sign In</Link>
          </div>
      </div>
      <div className="green-container">
        <div className="main-pic">
          <img src={mainPic} alt="organizer" />
        </div>
        <div className="main-info">
            <h1>Link Area - Handy Bookmark Manager</h1>
            <h2>Keep your links organized</h2>
            <div className="promo well">
                <p>Linka.in is your calm haven in World Wide Web. </p>
              <p>It provides a handy service for keeping bookmarks to all interesting pages you have encountered in web. </p>  
              <p>Your finding here will be well structured and easy searchable.</p> 
              <p>Your bookmark collection at linka.in is  browser and device independent .</p> 
          </div>
          <Link onClick={onDemo} to="/links"><button className="orange-button" id="demo">Try demo</button> </Link>
        </div>
      </div>
    </div>
  );
};

export default Landing;

import moment from "moment";
import React, { useState } from "react";
//import Spiner from "../spiner";
//import ErrorIndicator from "../error-indicator";
import AddForm from "../add-form";
import ItemActionButton from "../item-action-button";
import ItemLink from "./item-link";

import "./link-list.css";

export default function LinkList(props) {
  //const [loading, setLoading] = useState(false);
  //const [error, setError] = useState(false);
  const [editId, setEditId] = useState(null);

  /*
    const onError = error => {
        setError(true);
        setLoading(false);
    };

    if (loading) {
        return <Spiner />;
    }
    if (error) {
        return <ErrorIndicator />;
    }
   */
  function showTags(tags) {
    if (!Array.isArray(tags)) return null;
    return tags.map((item, i) => <li key={i}>{item}</li>);
  }

  const items = props.links.map(item => {
    if (item._id === editId) {
      return (
        <li key={item._id}>
          <AddForm
            obj={item}
            tags={props.tags}
            onUpdateLink={props.onUpdateLink}
            onUpdateFinish={() => setEditId(null)}
          />
        </li>
      );
    } else
      return (
        <li key={item._id}>
          <div className="item_date">{moment(item.date).format('DD-MM-YYYY')}</div>
          <div>
            <ItemLink url={item.url} title={item.title} />
            <ItemActionButton action={() => setEditId(item._id)} title="Edit" />
            <ItemActionButton action={() => props.onDelete(item._id)} title="Delete" />
          </div>
          <div>
            <ItemLink url={item.url} title={item.url} type="url" />
          </div>
          <ul className="link-tags">{showTags(item.tag)}</ul>
        </li>
      );
  });

  return <ul className="link_list">{items}</ul>;
}

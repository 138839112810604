import React from 'react';

import './tag-list.css';

const TagList = ({tags, onTagClick}) => {

    const elements = tags.map((item, index) => {
        const {_id, name, total} = item;
        return <button key={_id} onClick={()=>onTagClick(name)}>{name}({total}) </button>
    })
    return (
        <ul className="tag-list">
           {elements}
        </ul>
    )
}

export default TagList;
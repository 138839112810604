import React, { useState } from "react";
import "./add-form.css";

const AddForm =  props => {
  const [url, setUrl] = useState(props.obj ? props.obj.url : "");
 // const [id, setId] = useState(props.obj ? props.obj._id : null);
  const [tags, setTags] = useState(
    props.obj && props.obj.tag ? props.obj.tag : Array(5).fill("")
  );
  const [showTagHint, setShowTagHint] = useState(Array(5).fill(false));

  const handleInputChange = event => {
    const target = event.target;
    //const value = target.type === "checkbox" ? target.checked : target.value;
    const value = target.value;
    const name = target.name;
    if (/^tag/.test(name)) {
      let i = name.substring(3);
      setTags(changeArray(tags, i, value));
      setShowTagHint(changeArray(showTagHint, i, value !== ""));
    } else if (name === "url") {
      setUrl(value);
    }
  };
  const handleSubmit = event => {
    if (url !== "") {
      let tagsClean = [...tags];
      tagsClean = tagsClean.filter(e => e !== "");
      let id = props.obj ? props.obj._id : null;
      if (id) {
        props.onUpdateLink({
          url: url,
          title: "***",
          tag: tagsClean,
          id: id,
        });
        props.onUpdateFinish();
      } else {
        props.onAddLink({
          url: url,
          title: "***",
          tag: tagsClean,
        });
      }
      setUrl("");
      setTags(Array(5).fill(""));
      setShowTagHint(Array(5).fill(false));
    }
    event.preventDefault();
  };

  function changeArray(ar, ind, value) {
    let arCopy = ar.slice();
    arCopy[ind] = value;
    return arCopy;
  }

  const onTagHintClick = (i, el) => {
    let newTags = tags.slice();
    newTags[i] = el.name;
    setTags(newTags);
    setShowTagHint(changeArray(showTagHint, i, false));
  };

  var tagsAr = [];
  var hint = Array(5).fill("");

  for (let i = 0; i < 5; i++) {
    let tagUp = tags[i]? tags[i].charAt(0).toUpperCase() + tags[i].slice(1) : '***';
    if (showTagHint[i]) {
      var li = props.tags
        .filter(el => ((el.name.indexOf(tags[i]) === 0) ||(el.name.indexOf(tagUp) === 0)))
        .map(el => <li onClick={() => onTagHintClick(i, el)}>{el.name}</li>);
      
      hint[i] = li.length ? (
        <div className="hint">
          <ul>{li}</ul>
        </div>
      ) : (
        ""
      );
    } else {
      hint[i] = "";
    }

    tagsAr.push(
      <div className="tags" key={"tk"+i}>
        <input className="tag-input"
          type="text"
          autoComplete="off"
          name={"tag" + i}
          key={"tag" + i}
          id={"tag" + i}
          value={tags[i]}
          onChange={handleInputChange}
          onBlur={() =>
            setTimeout(
              () => setShowTagHint(changeArray(showTagHint, i, false)),
              2000
            )
          }
        />
        {hint[i]}
      </div>
    );
  }

  var errors = "";

  if (!props.errors?.length) {
    errors = "";
  } else {
    errors = props.errors.map(el => {
      return (
        <li key={el.name} dangerouslySetInnerHTML={{ __html: el.message }}></li>
      );
    });
    errors = <ul className="validatonErrors">{errors}</ul>;
  }

  return (
    <form className="" onSubmit={handleSubmit}>
      {errors}
      <div className="div-group">
        <label htmlFor="url">URL: </label>
        <input
          name="url"
          className="url-input"
          value={url}
          id="url"
          type="text"
          onChange={handleInputChange}
        />
      </div>
      <div className="div-group">
        <label htmlFor="tag0">Tags: </label>
        {tagsAr}
        <input type="submit" className="green-button" value="Save" />
      </div>
    </form>
  );
};
export default AddForm;

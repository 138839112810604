import React, { Component } from "react";

import './search.css';

export default class Search extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      searchText: this.props.searchText,
      showHint: 1,
    };
  }

  onSearchChange = e => {
    const searchText = e.target.value;
    this.setState({ showHint: true });
    this.setState({ searchText });
    this.props.onSearchChange(searchText);
  };

  onSearchClear = e => {
    this.textInput.current.focus();
    this.textInput.current.value = "";
    this.props.onSearchClear();
  };

  render() {
    var hint;
    if (this.state.searchText !== "") {
      var li = this.props.tags
        .filter(el => el.name.indexOf(this.state.searchText) === 0)
        .map(el => (
          <li
            onClick={() => {
              this.textInput.current.value = el.name;
              this.props.onSearchChange(el.name);
              this.setState({ showHint: false });
            }}
          >
            {el.name}
          </li>
        ));
      hint = li.length ? <ul className="search_hint">{li}</ul> : "";
    } else {
      hint = "";
    }

    if (!this.state.showHint) hint = "";

    return (
      <form>
        <div className="search-form">
            <label class="search-label" htmlFor="search">Search:</label>
            <input class="search-input" type="text" value={this.props.searchText} ref={this.textInput}
                onChange={this.onSearchChange}
                onClick={this.onSearchClear}
            />
        {hint ? (
          hint
        ) : (
                <button type="button" onClick={this.onSearchClear}>X</button>
        )}
      </div></form>
    );
  }
}

import React from 'react';
import './spiner.css';

const Spiner = () => {
    return (
        <div className="loadingio-spinner-gear-xeghwc499r9"><div className="ldio-arzaxdnne7c">
       <div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div></div>
    );
}

export default Spiner;

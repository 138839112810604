import React from "react";
import { Link } from "react-router-dom";

const Logout = ({ onDemo }) => {
    return (
        <div>
          <div>Linka</div>
          <Link onClick={onDemo} to="/links">
            Demo
          </Link>
          &nbsp;
          <Link to="/register">Sign Up</Link>&nbsp;
          <Link to="/login">Sign In</Link>
        </div>
      );

};

export default Logout;
import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

import './auth.css';

//import  { setAlert } from '../../actions/alert';
//import  { register } from '../../actions/auth';
//import PropTypes from 'prop-types';

const Register = (props) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        password2: ''
    });
    
    if (props.logined) {
        return <Redirect to="/links" />
    }
    
    const { name, email, password, password2 } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
    const onSubmit = async e => {
        e.preventDefault();
        if (password !== password2) {
            alert('Passwords do not match');
        } else {
            props.onSubmit(formData);
        }
    };
    /*
    if (isAuthenticated) {
      return <Redirect to="/dashboard"/>;
   }
   */
    return <div className="center narrow">
        <h1 className="large text-primary">Sign Up</h1>
        <p className="lead"><i className="fas fa-user"></i> Create Your Account</p>
        <form className="form" onSubmit={e => onSubmit(e)}>
            <div className="form-group">
                <input type="text" placeholder="Name" name="name"
                    value={name}
                    onChange={e => onChange(e)}
                    required />
            </div>
            <div className="form-group">
                <input type="email" placeholder="Email Address" name="email"
                    value={email}
                    onChange={e => onChange(e)}
                    required />
                {/*  <small className="form-text"
                ><This site uses Gravatar so if you want a profile image, use a
            Gravatar email</small
> */}
            </div>
            <div className="form-group">
                <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={e => onChange(e)}
                    minLength="2"
                />
            </div>
            <div className="form-group">
                <input
                    type="password"
                    placeholder="Confirm Password"
                    name="password2"
                    value={password2}
                    onChange={e => onChange(e)}
                    minLength="2"
                />
            </div>
            <input type="submit" className="green-button" value="Register" />
        </form>
        <p className="my-1">
            Already have an account? <Link to="/login">Sign In</Link>
        </p>
        </div>
    ;
}
export default Register;

/*
Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { setAlert, register })(Register);
*/
import React from 'react';

import './panel.css';

const Panel = ({title, children}) => {

    if (title === undefined) {
        return (
            <div className="untitled-panel">
                <div>{children}</div>
            </div>
        )
    } else {
        return (
            <div className="panel">
                <div className="title">{title}</div>
                <div className="content">{children}</div>
            </div>
        )
    }
}

export default Panel; 